<template>
  <footer class="bg-white">
    <div
      class="mx-auto max-w-7xl px-6 py-8 align-center flex items-center lg:px-8"
    >
      <p class="text-center w-full text-xs leading-5 text-gray-500">
        &copy; 2024 Bilton Conseil, Inc. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  // Options du composant, le cas échéant
};
</script>

<style scoped>
/* Styles spécifiques à votre pied de page ici */
</style>
